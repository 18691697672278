import * as React from 'react'
import Login from "./login";
import {navigate} from "gatsby";

function Index() {
    const replaceHashPath = () => {
        if (typeof window !== 'undefined') {
            let path = window.location.href;
            const hasHash = path.includes('/#/app');
            if (hasHash) {
                path = path.replace(/^(.*?)\/#\//, '/')
                if (path) {
                    navigate(path);
                }
            }
        }
    }
    replaceHashPath()

    return <Login />
}

export default Index